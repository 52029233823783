import React, { useEffect, useState, useRef, useContext } from "react";
import SEO from "components/seo";
import Layout from "components/layout";
import { Root, Content, ImageWrapper, Image, FormContent, FormContainer } from "./purchase";
import { Router } from "@reach/router";
import api from "services/api";
import styled from "styled-components";
import themeColor from "styles/theme-color";
import iconClap from "../images/payments/icon-clap.png";
import { desktop, mobile } from "styles/media-queries";
import ClipboardJS from "clipboard";
import { ContentContext } from "hooks/use-content";
import { Badge } from "components/badge";
import { SplashScreen } from "components/splash-screen";

const Header = styled.div`
    text-align: center;
    color: ${themeColor("fontDark")};
    font-size: 32px;
    font-weight: bold;
`;

const Heading = styled.div`
    font-size: 24px;
    margin: 32px 0;
`;

const EntryCode = styled.div`
    position: relative;
    border-radius: 10px;
    margin-top: 18px;
    background-color: ${themeColor("backgroundLight")};
    display: flex;
    font-size: 32px;
    color: ${themeColor("blue")};
    font-weight: bold;
    justify-content: center;
    padding: 6px 0;
    box-shadow: inset 0 1px 3px 0 rgba(210, 216, 224, 0.35);
`;

const CopyCodeToClipboardButton = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    transform: translateY(-50%);
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    color: ${themeColor("fontSemiDark")};
    font-weight: normal;
`;

const CongratsImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px 0 40px 0;
`;

const CongratsImage = styled.img`
    object-fit: contain;
`;

export const ConfirmPurchaseRoot = styled.div`
    padding-top: 2rem;

    @media ${desktop.andUp().toString()} {
        padding-left: 7rem;
    }
`;

const DownloadAppSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 72px;

    @media ${mobile.andDown().toString()} {
        flex-direction: column;
    }
`;

const DownloadAppText = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${themeColor("fontSemiDark")};
`;

const Badges = styled.div`
    display: flex;

    @media ${mobile.andDown().toString()} {
        margin-top: 20px;
    }
`;

const StyledBadge = styled(Badge)`
    height: 34px;

    &:last-of-type {
        margin-left: 20px;
    }
`;

const Email = styled.span`
    color: ${themeColor("blue")};
`;

function ConfirmPurchaseSection(props: any & { purchaseId: string }) {
    const content = useContext(ContentContext);
    const commonContent = content.common;
    const confirmPurchaseContent = content.confirmPurchase;

    const [entryCode, setEntryCode] = useState<string | undefined>();

    const copyCodeToClipboardButtonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const fetchCode = async () => {
            const completionResult = await api.isPurchaseCompleted({ PurchaseId: props.purchaseId });

            if (completionResult.isSuccess) {
                if (completionResult.result.IsCompleted) {
                    const purchaseResult = await api.purchaseById({ PurchaseId: props.purchaseId });

                    if (purchaseResult.isSuccess) {
                        setEntryCode(purchaseResult.result.TeamPassword || undefined);
                    }
                } else {
                    fetchCode();
                }
            }
        };

        fetchCode();
    }, [props.purchaseId]);

    useEffect(() => {
        copyCodeToClipboardButtonRef.current &&
            new ClipboardJS(copyCodeToClipboardButtonRef.current, {
                text: () => entryCode,
            });
    }, [entryCode]);

    return (
        <FormContent>
            {entryCode ? (
                <>
                    <Header>{confirmPurchaseContent.header}</Header>
                    <CongratsImageContainer>
                        <CongratsImage src={iconClap} />
                    </CongratsImageContainer>
                    <Heading>{confirmPurchaseContent.entryCodeText}</Heading>
                    <EntryCode>
                        {entryCode}
                        <CopyCodeToClipboardButton ref={copyCodeToClipboardButtonRef}>
                            {confirmPurchaseContent.copyEntryCodeLabel}
                        </CopyCodeToClipboardButton>
                    </EntryCode>
                    <Heading>{confirmPurchaseContent.teamVisibilityText}</Heading>
                    <DownloadAppSection>
                        <DownloadAppText>{confirmPurchaseContent.downloadAppText}</DownloadAppText>
                        <Badges>
                            <StyledBadge link={commonContent.appStore.link} image={commonContent.appStore.logo} />
                            <StyledBadge link={commonContent.googlePlay.link} image={commonContent.googlePlay.logo} />
                        </Badges>
                    </DownloadAppSection>
                    <Heading>{confirmPurchaseContent.checkEmailText}</Heading>
                    <Heading>
                        {confirmPurchaseContent.contactText}
                        <Email>{confirmPurchaseContent.contactEmail}</Email>
                    </Heading>
                </>
            ) : (
                <SplashScreen />
            )}
        </FormContent>
    );
}

export default function ConfirmPurchase() {
    return (
        <Layout>
            <SEO title="Purchase" />
            <Root>
                <Content>
                    <ImageWrapper>
                        <Image />
                    </ImageWrapper>
                    <FormContainer>
                        <Router>
                            <ConfirmPurchaseSection path="/confirm-purchase/:purchaseId" />
                        </Router>
                    </FormContainer>
                </Content>
            </Root>
        </Layout>
    );
}
